import React, { FC } from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import SectionHeader from 'components/layout/SectionHeader';
import { useGetUsersQuery } from 'services/api/adminUsers.api';
import { User } from 'services/types/user';

const USER_ATTRIBUTES: GridColDef<User>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'name',
    headerName: 'Nombre',
    width: 300,
  },
  {
    field: 'projectId',
    headerName: 'Empresa',
    width: 100,
    valueFormatter: (value: number | null): string | number => value ?? '(admin)',
  },
  {
    field: 'isAdmin',
    headerName: 'Es admin?',
    width: 100,
    valueFormatter: (value: boolean) => (value ? 'Si' : 'No'),
  },
  {
    field: 'subProjectId',
    headerName: 'Subproyecto',
    width: 100,
  },
  {
    field: 'subProjectIds',
    headerName: 'Subproyecto(s)',
    width: 140,
  },
  {
    field: 'isTestUser',
    headerName: 'Es test?',
    width: 100,
    valueFormatter: (value: boolean) => (value ? 'Si' : 'No'),
  },
];

const UsersTable: FC = () => {
  const { data: users } = useGetUsersQuery(null);

  return (
    <Box style={{ width: '100%' }}>
      <SectionHeader text="Lista de Usuarios" />
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: 2,
        }}
      >
        <DataGrid
          rows={users || []}
          columns={USER_ATTRIBUTES}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            color: 'Black',
            height: 'calc(100vh - 150px)',
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#e8f0fe',
            },
            border: 'none',
          }}
        />
      </Box>
    </Box>
  );
};

export default UsersTable;
