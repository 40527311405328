import React, { useState, useEffect, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DeleteIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import SimpleDash from 'components/charts/Dashboard';
import SectionHeader from 'components/layout/SectionHeader';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useGetProjectQuery,
  useAdminGetProjectQuery,
  useAdminGetProjectWithIdMutation,
  useEditProjectMutation,
  useAdminGetProjectAnalyticsMutation,
  useDeleteProjectImageMutation,
  useUploadProjectImagesMutation,
  useDeleteProjectQuoteMutation,
  useUploadProjectQuotesMutation,
} from 'services/api/projects.api';
import priceFormat from 'services/format/priceFormat';
import type { EditProjectBody, Project } from 'services/types/project';

import PropertyTypesEditor from './components/ProjectEditPropertyTypes';
import ScopesSelector from './components/ProjectScopesSelector';
import ProjectSelector from './components/ProjectSelector';
import PromptEditComponent from './components/PromptEditComponent';

const ProjectView: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const user = useCurrentUser();
  const [project, setProject] = useState<Project | null>(null);
  const isMasterProject = !project?.masterProjectId;
  const [masterProject, setMasterProject] = useState<Project | null>(null);
  const [isSubProjectSelected, setIsSubProjectSelected] = useState(false);
  const { data } = user?.isAdmin && project ? useAdminGetProjectQuery(project.id) : useGetProjectQuery(null);
  const [getProjectAnalytics, { data: projectAnalytics }] = useAdminGetProjectAnalyticsMutation();

  const [getProjectWithId] = useAdminGetProjectWithIdMutation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();

  const fetchProject = async () => {
    if (user?.isAdmin && projectId) {
      const intId = parseInt(projectId, 10);
      try {
        const proyecto = await getProjectWithId(intId).unwrap();
        if (proyecto) {
          if (proyecto.masterProject) {
            setMasterProject(proyecto.masterProject);
            setIsSubProjectSelected(true);
          } else {
            setMasterProject(null);
            setIsSubProjectSelected(false);
          }
          setProject(proyecto);
        }
      } catch (e) {
        navigate('/project');
      }
    } else {
      navigate('/project');
    }
  };

  useEffect(() => {
    fetchProject().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [projectId]);

  const [deleteProjectImage] = useDeleteProjectImageMutation();
  const [imagesToUpload, setImagesToUpload] = useState<File[] | null>(null);
  const [uploadProjectImages] = useUploadProjectImagesMutation();

  const [deleteProjectQuote] = useDeleteProjectQuoteMutation();
  const [quotesToUpload, setQuotesToUpload] = useState<File[] | null>(null);
  const [uploadProjectQuotes] = useUploadProjectQuotesMutation();

  const [isEditDescription, setIsEditDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState(project?.description || '');
  const [currentEditingSubProjectId, setCurrentEditingSubProjectId] = useState<number | null>(null);
  const [editedSubProjectDescription, setEditedSubProjectDescription] = useState('');
  const [editProject] = useEditProjectMutation();
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [minimumPropertyUFPrice, setMinimumPropertyUFPrice] = useState(Number(project?.minimumPropertyUFPrice ?? 0));
  const [aiScoreWeight, setAiScoreWeight] = useState(Number(project?.aiScoreWeight ?? 0));
  const [formRequestUrl, setFormRequestUrl] = useState<string>(project?.formRequestUrl || '');
  const formattedCost = priceFormat.format(projectAnalytics ? projectAnalytics.cost : 0);
  const averageCost =
    projectAnalytics && projectAnalytics.clientsCount > 0
      ? projectAnalytics.cost / Number(projectAnalytics.clientsCount)
      : 0;
  const formattedAverageCost = priceFormat.format(averageCost);

  const subProjects = project?.subProjects ?? [];
  const sortedSubProjects = [...subProjects].sort((a, b) => (a.name > b.name ? 1 : -1));

  dayjs.locale('es');
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().startOf('month'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs().endOf('day'));

  const handleEditProject = (id: number, body: EditProjectBody) => {
    editProject({ id, body })
      .then(() => {
        if (project) {
          setProject({ ...project, ...body });
          setRefetchFlag(!refetchFlag);
        }
      })
      .catch((e) => console.error(e)); // eslint-disable-line no-console
    setIsEditDescription(false);
  };

  const handleSaveScopes = (scopes: string[]) => {
    if (project) {
      handleEditProject(project.id, { scopes });
    }
  };

  const handleEditSubProject = (id: number, body: EditProjectBody) => {
    editProject({ id, body })
      .then(() => {
        setRefetchFlag(!refetchFlag);
      })
      .catch((e) => console.error(e)); // eslint-disable-line no-console
    setCurrentEditingSubProjectId(null);
    setEditedSubProjectDescription('');
    window.location.reload();
  };

  const handleSelectSubProject = (subProject: Project) => {
    if (user?.isAdmin) {
      setIsSubProjectSelected(true);
      navigate(`/project/${subProject.id}`);
    } else {
      setMasterProject(project);
      setProject(subProject);
      setIsSubProjectSelected(true);
    }
  };

  const handleGoToMasterProject = () => {
    if (masterProject) {
      if (user?.isAdmin) {
        setIsSubProjectSelected(false);
        navigate(`/project/${masterProject.id}`);
      } else {
        setProject(masterProject);
        setIsSubProjectSelected(false);
      }
    }
  };

  const handleProjectSelect = (selectedProject: Project | null) => {
    if (selectedProject) {
      navigate(`/project/${selectedProject.id}`);
    }
  };

  const handleDeleteImage = (fileName: string) => {
    if (project) {
      deleteProjectImage({ projectId: project.id, fileName })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const handleUploadImages = () => {
    if (imagesToUpload && project) {
      uploadProjectImages({ projectId: project.id, files: imagesToUpload })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const getImagesError = () => {
    if (!imagesToUpload || !imagesToUpload.length) {
      return 'No hay imágenes seleccionadas';
    }
    if (imagesToUpload.some((file) => file.size > 300_000)) {
      return 'Hay imágenes que pesan más de 300kb';
    }
    return null;
  };

  const handleDeleteQuote = (fileName: string) => {
    if (project) {
      deleteProjectQuote({ projectId: project.id, fileName })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const handleUploadQuotes = () => {
    if (quotesToUpload && project) {
      uploadProjectQuotes({ projectId: project.id, files: quotesToUpload })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const getQuotesError = () => {
    if (!quotesToUpload || !quotesToUpload.length) {
      return 'No hay cotizaciones seleccionadas';
    }
    if (quotesToUpload.some((file) => file.size > 300_000)) {
      return 'Hay cotizaciones que pesan más de 300kb';
    }
    return null;
  };

  useEffect(() => {
    if (project && project.description) {
      setEditedDescription(project.description);
    }
    if (project) {
      setMinimumPropertyUFPrice(project.minimumPropertyUFPrice ?? 0);
      setFormRequestUrl(project.formRequestUrl || '');
    }
    if (user?.isAdmin && project) {
      getProjectAnalytics(project.id).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [project]);

  useEffect(() => {
    if (data) {
      setProject(data);
    }
  }, [data]);

  const button = (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Box alignContent="center" textAlign="left">
        {user?.isAdmin && (
          <ProjectSelector onSelect={handleProjectSelect} projectId={project?.id} refetchFlag={refetchFlag} />
        )}
        <DatePicker value={fromDate} onChange={setFromDate} label="Fecha de Inicio" sx={{ mt: 1 }} />
        <DatePicker value={toDate?.endOf('day')} onChange={setToDate} label="Fecha de Fin" sx={{ mt: 1 }} />
      </Box>
    </LocalizationProvider>
  );

  return (
    <Box pb={4}>
      <SectionHeader text="Proyecto" button={button} />
      {isSubProjectSelected && (
        <PrimaryBtn onClick={handleGoToMasterProject} style={{ marginBottom: '20px' }}>
          Volver a la Inmobiliaria
        </PrimaryBtn>
      )}
      <Typography variant="h6" pb="25px" pt="25px">
        {project?.subProjects?.length ? 'Inmobiliaria' : 'Proyecto'}: {project?.name}
      </Typography>
      {project?.subProjects?.length ? (
        <Box pb={2}>
          <Typography variant="h6">Proyectos</Typography>
          {project?.subProjects?.map((subProject) => (
            <Typography
              variant="body1"
              key={subProject.id}
              onClick={() => handleSelectSubProject(subProject)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              - {subProject.name}
            </Typography>
          ))}
        </Box>
      ) : null}
      <Typography variant="h6">
        Número de teléfono:{' '}
        {project?.phoneNumber && project?.phoneNumber !== 'Not available yet'
          ? project?.phoneNumber
          : masterProject?.phoneNumber}
      </Typography>
      <Typography variant="h4" pt="25px">
        {project ? (
          <SimpleDash
            project={project}
            fromDate={fromDate?.locale('en').format() || dayjs().startOf('month').locale('en').format()}
            toDate={toDate?.locale('en').format() || dayjs().endOf('day').locale('en').format()}
          />
        ) : (
          'Selecciona un proyecto para ver las estadísticas'
        )}
      </Typography>

      <Typography variant="h6" pt="25px">
        Cotizaciones: {project?.apartmentTypes?.join(', ') || '--Ninguna disponible por ahora--'}
      </Typography>
      <Typography variant="h6" pt="25px">
        Imágenes: {project?.apartmentImages?.join(', ')}
      </Typography>

      {user?.isAdmin && project && (
        <Box sx={{ mt: 4, borderTop: '1px solid #ccc', pt: 2 }}>
          <Typography variant="h6">------ Solo para admins ------</Typography>
          <Typography variant="h6">ID: {project?.id}</Typography>
          <Typography variant="h6">Número de clientes: {projectAnalytics?.clientsCount}</Typography>
          <Typography variant="h6">Costo APIs externas: {formattedCost}</Typography>
          <Typography variant="h6">Costo promedio por cliente: {formattedAverageCost}</Typography>
          <Typography variant="h6" pt="25px">
            Imágenes:
          </Typography>
          {project?.images && (
            <>
              {Object.keys(project.images).map((key) => (
                <div key={key}>
                  <a
                    href={project.images?.[key]?.publicFileURL}
                    target="_blank"
                    rel="noreferrer"
                    key={key}
                    style={{ textDecoration: 'underline' }}
                  >
                    {key}
                  </a>
                  <IconButton onClick={() => handleDeleteImage(key)} color="error">
                    <DeleteIcon />
                  </IconButton>
                  <br />
                </div>
              ))}
            </>
          )}
          <label htmlFor="file">
            Imágenes (Ej: foto_pieza_2.jpg. Sin tilde, ni espacios ni caracteres especiales)
            <input
              id="file"
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  setImagesToUpload(Array.from(e.target.files));
                } else if (e.target.files && !e.target.files.length) {
                  setImagesToUpload(null);
                }
              }}
              placeholder="Seleccionar imágenes"
              style={{ marginLeft: '10px' }}
            />
          </label>
          <br />
          <PrimaryBtn onClick={handleUploadImages} disabled={!!getImagesError()} sx={{ my: 2 }}>
            Subir imágenes
          </PrimaryBtn>
          {getImagesError() && <Typography variant="body1">{getImagesError()}</Typography>}
          <Typography variant="h6" pt="25px">
            Cotizaciones:
          </Typography>
          {project?.quotes && (
            <>
              {Object.keys(project.quotes).map((key) => (
                <div key={key}>
                  <a
                    href={project.quotes?.[key]?.publicFileURL}
                    target="_blank"
                    rel="noreferrer"
                    key={key}
                    style={{ textDecoration: 'underline' }}
                  >
                    {key}
                  </a>
                  <IconButton onClick={() => handleDeleteQuote(key)} color="error">
                    <DeleteIcon />
                  </IconButton>
                  <br />
                </div>
              ))}
            </>
          )}
          <label htmlFor="file">
            Archivos (Ej: cotizacion_tipo_A.pdf. Sin tilde, ni espacios ni caracteres especiales)
            <input
              id="file"
              type="file"
              accept=".pdf"
              multiple
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  setQuotesToUpload(Array.from(e.target.files));
                } else if (e.target.files && !e.target.files.length) {
                  setQuotesToUpload(null);
                }
              }}
              placeholder="Seleccionar cotizaciones"
              style={{ marginLeft: '10px' }}
            />
          </label>
          <br />
          <PrimaryBtn onClick={handleUploadQuotes} disabled={!!getQuotesError()} sx={{ my: 2 }}>
            Subir cotizaciones
          </PrimaryBtn>
          {getQuotesError() && <Typography variant="body1">{getQuotesError()}</Typography>}

          {project?.devUrl && <Typography variant="h6">DevUrl: {project?.devUrl}</Typography>}
          {project?.scheduleVisitInstructions && (
            <>
              <Typography variant="h6">Instrucciones para agenda de horas</Typography>
              <Typography variant="body1" pb="25px">
                {project?.scheduleVisitInstructions}
              </Typography>
            </>
          )}
          {project?.availableDates && project?.availableDates.length > 0 && (
            <>
              <Typography variant="h6">Horarios disponibles</Typography>
              {project.availableDates.map((date) => (
                <Typography variant="body1" key={`${date.day}-${date.startTime}`}>
                  {date.day}: {date.startTime} - {date.endTime}
                </Typography>
              ))}
            </>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="600px"
            pt="25px"
          >
            <Typography variant="h6">Url Form Monday (Inicio):</Typography>
            <input
              type="text"
              value={formRequestUrl}
              onChange={(event) => setFormRequestUrl(event.target.value)}
              style={{ width: '200px' }}
            />
            <PrimaryBtn onClick={() => handleEditProject(project.id, { formRequestUrl })}>Guardar</PrimaryBtn>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="600px"
            pt="25px"
          >
            <Typography variant="h6">Precio mínimo (UF):</Typography>
            <input
              type="number"
              value={minimumPropertyUFPrice}
              onChange={(event) => setMinimumPropertyUFPrice(Number(event.target.value))}
              style={{ width: '100px' }}
            />
            <PrimaryBtn onClick={() => handleEditProject(project.id, { minimumPropertyUFPrice })}>Guardar</PrimaryBtn>
          </Box>
          <Typography variant="h6" pt="10px">
            Pie mínimo:{' '}
            {project.minimumDownPaymentRate ? `${(project.minimumDownPaymentRate ?? 0) * 100}%` : '- (Default: 20%)'}
          </Typography>
          <Typography variant="h6" pt="10px">
            Beta puntaje financiero:{' '}
            {project.financialScoreWeight ? `${project.financialScoreWeight * 100}%` : '- (Default: 50%)'}
          </Typography>
          <Typography variant="h6" pt="10px">
            Beta puntaje interés:{' '}
            {project.financialScoreWeight
              ? `${Math.round((1 - project.financialScoreWeight) * 100)}%`
              : '- (Default: 50%)'}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h6" mr={4}>
              AI Score Weight:
            </Typography>
            <input
              type="number"
              value={aiScoreWeight}
              onChange={(event) => setAiScoreWeight(Number(event.target.value))}
              style={{ width: '100px', marginRight: '30px' }}
            />
            <PrimaryBtn onClick={() => handleEditProject(project.id, { aiScoreWeight })}>Guardar</PrimaryBtn>
          </Box>
          <Typography variant="h6" mt="5px">
            Chat fuera de whatsapp:{' '}
            {project.pusherIdentification ? (
              <a href={`/demoChat/${project.pusherIdentification}`} target="_blank" rel="noreferrer">
                Ir al chat
              </a>
            ) : (
              'No disponible'
            )}
          </Typography>
          <Typography variant="h6" mt="10px">
            Deshabilitar validación financiera al guardar ingresos:{' '}
            <PrimaryBtn
              onClick={() =>
                handleEditProject(project.id, {
                  skipFinancialDataValidationOnIncome: !project.skipFinancialDataValidationOnIncome,
                })
              }
              style={{ marginLeft: '10px', minWidth: '50px' }}
            >
              {project.skipFinancialDataValidationOnIncome ? 'Sí' : 'No'}
            </PrimaryBtn>
          </Typography>
          <Typography variant="h6" pt="10px">
            Funcionalidad de búsqueda de propiedades:
            <PrimaryBtn
              onClick={() => handleEditProject(project.id, { hasProperties: !project.hasProperties })}
              style={{ marginLeft: '10px', minWidth: '50px' }}
            >
              {project.hasProperties ? 'Habilitada' : 'Deshabilitada'}
            </PrimaryBtn>
          </Typography>
          <Typography variant="h6" mt="10px">
            Funcionalidad de guardar datos de empleo:{' '}
            <PrimaryBtn
              onClick={() =>
                handleEditProject(project.id, {
                  hasSaveEmployementDetailsFunctionality: !project.hasSaveEmployementDetailsFunctionality,
                })
              }
              style={{ marginLeft: '10px', minWidth: '50px' }}
            >
              {project.hasSaveEmployementDetailsFunctionality ? 'Habilitada' : 'Deshabilitada'}
            </PrimaryBtn>
          </Typography>
          <Typography variant="h6" mt="10px">
            Funcionalidad para guardar tipo de cliente:{' '}
            <PrimaryBtn
              onClick={() =>
                handleEditProject(project.id, {
                  includeSaveBuyerTypeFunction: !project.includeSaveBuyerTypeFunction,
                })
              }
              style={{ marginLeft: '10px', minWidth: '50px' }}
            >
              {project.includeSaveBuyerTypeFunction ? 'Habilitada' : 'Deshabilitada'}
            </PrimaryBtn>
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', marginTop: '10px' }}>
            <Typography variant="h6" pt="5px">
              Funcionalidad de scopes: {project.hasScopeFunctionality ? project.scopes?.join(',') : 'Deshabilitada'}
            </Typography>
            <PrimaryBtn
              onClick={() => handleEditProject(project.id, { hasScopeFunctionality: !project.hasScopeFunctionality })}
              style={{ marginLeft: '10px', minWidth: '50px' }}
            >
              {project.hasScopeFunctionality ? '✘' : '✔'}
            </PrimaryBtn>
          </div>
          <div>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">- Editar Scopes:</Typography>
              <ScopesSelector project={project} onSave={handleSaveScopes} />
            </Box>
          </div>

          <div style={{ marginBottom: '50px' }}>
            <Typography variant="h6" mt="25px">
              - Editar tipos de propiedades:
            </Typography>
            <PropertyTypesEditor project={project} handleEditProject={handleEditProject} />
          </div>

          <PromptEditComponent project={project} masterProject={masterProject} handleEditProject={handleEditProject} />

          <Typography variant="h3" pt="25px" pb="10px">
            Funciones adicionales al prompt:
          </Typography>
          {project.openAIFunctions?.map(({ function: { name, description, parameters } }) => {
            if (!parameters) {
              return (
                <Typography variant="body1" key={name}>
                  - <strong>{name}</strong>: {description}
                </Typography>
              );
            }
            const { properties } = parameters;
            const props = Object.keys(properties)
              .map(
                (key) =>
                  `${key}[${
                    properties[key].enum?.join(',') ?? properties[key].items?.enum.join(',') ?? properties[key].type
                  }]`
              )
              .join(', ');
            return (
              <Typography variant="body1" key={name}>
                - <strong>{name}</strong>({props}): {description}
              </Typography>
            );
          })}
          {!isMasterProject ? (
            <>
              <Grid mb={2}>
                <Typography variant="h6" pt="25px">
                  Descripción (es la que se incluye dentro de `&lt;SUB_PROJECTS&gt;` en el prompt):
                </Typography>
                {isEditDescription ? (
                  <>
                    <PrimaryBtn onClick={() => handleEditProject(project.id, { description: editedDescription })}>
                      Guardar
                    </PrimaryBtn>
                    <PrimaryBtn onClick={() => setIsEditDescription(false)}>Cancelar</PrimaryBtn>
                  </>
                ) : (
                  <PrimaryBtn onClick={() => setIsEditDescription(true)}>Editar</PrimaryBtn>
                )}
              </Grid>
              <textarea
                value={(isEditDescription ? editedDescription : project.description) || ''}
                cols={isEditDescription ? 120 : 80}
                rows={5}
                onChange={(event) => setEditedDescription(event.target.value)}
                disabled={!isEditDescription}
              />
            </>
          ) : (
            <>
              {sortedSubProjects.map((subProject) => (
                <Box key={subProject.id}>
                  <Typography variant="h6" pt="25px">
                    Descripción de {subProject.name}
                  </Typography>
                  <Grid mb={2} display="flex" alignItems="center">
                    <textarea
                      value={
                        (currentEditingSubProjectId === subProject.id
                          ? editedSubProjectDescription
                          : subProject.description) || ''
                      }
                      cols={120}
                      rows={5}
                      onChange={(event) => setEditedSubProjectDescription(event.target.value)}
                      disabled={currentEditingSubProjectId !== subProject.id}
                      style={{ marginRight: '20px' }}
                    />
                    {currentEditingSubProjectId === subProject.id ? (
                      <>
                        <PrimaryBtn
                          onClick={() =>
                            handleEditSubProject(subProject.id, { description: editedSubProjectDescription })
                          }
                        >
                          Guardar
                        </PrimaryBtn>
                        <PrimaryBtn onClick={() => setCurrentEditingSubProjectId(null)}>Cancelar</PrimaryBtn>
                      </>
                    ) : (
                      <PrimaryBtn
                        onClick={() => {
                          setEditedSubProjectDescription(subProject.description || '');
                          setCurrentEditingSubProjectId(subProject.id);
                        }}
                      >
                        Editar
                      </PrimaryBtn>
                    )}
                  </Grid>
                </Box>
              ))}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProjectView;
