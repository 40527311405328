import React, { FC, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';
import DiffMatchPatch from 'diff-match-patch';
import type { EditProjectBody, Project } from 'services/types/project';
import prettifyPrompt from 'utils/prettifyPrompt';

import PromptHelperText from './PromptHelperText';
import PromptPlayground from './PromptPlayground';

const DiffComponent = ({ diff }: { diff: [number, string] }) => {
  const [diffType, text] = diff;
  const colors: Record<number, 'orange' | 'none' | 'lightgreen'> = {
    1: 'lightgreen',
    0: 'none',
    '-1': 'orange',
  };
  const decorations: Record<number, 'line-through' | 'none' | 'underline'> = {
    1: 'underline',
    0: 'none',
    '-1': 'line-through',
  };
  return (
    <span
      style={{
        backgroundColor: colors[diffType],
        textDecoration: decorations[diffType],
        whiteSpace: 'pre-line',
        fontSize: 13,
      }}
    >
      {text}
    </span>
  );
};

const DiffsComponent = ({ originalText, newText }: { originalText: string; newText: string }) => {
  if (!originalText || !newText || originalText === newText) {
    return null;
  }
  const dmp = new DiffMatchPatch();
  const diffs = dmp.diff_main(originalText, newText);
  return (
    <p style={{ lineHeight: '0.8em', marginTop: 0 }}>
      {diffs.map((diff, index) => (
        <DiffComponent diff={diff} key={`${index + 1}-${diff[1]}`} />
      ))}
    </p>
  );
};

interface Props {
  project: Project;
  masterProject: Project | null;
  handleEditProject: (projectId: number, data: EditProjectBody) => void;
}

const PromptEditComponent: FC<Props> = ({ project, masterProject, handleEditProject }: Props) => {
  const [editedPrompt, setEditedPrompt] = useState(project?.prompt || '');

  const [isEditPrompt, setIsEditPrompt] = useState(false);
  const [isEditCommonPrompt, setIsEditCommonPrompt] = useState(false);
  const [editedCommonPrompt, setEditedCommonPrompt] = useState(project?.commonPrompt || '');
  const [isEditMode, setEditMode] = useState(false);
  const [showPromptPlayground, setShowPromptPlayground] = useState(false);

  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if (project && project.prompt) {
      setEditedPrompt(project.prompt);
    }
    if (project && project.commonPrompt) {
      setEditedCommonPrompt(project.commonPrompt);
    }
  }, [project]);

  const htmlPrompt = prettifyPrompt(
    editedPrompt.replaceAll(
      '<COMMON_PROMPT>',
      `<!-- inicio prompt común -->${editedCommonPrompt || project?.commonPrompt || masterProject?.commonPrompt || '<COMMON_PROMPT>'}<!-- final prompt común -->`
    )
  );

  // eslint-disable-next-line react/no-danger
  const prettyPrompt = <div id="dummy" className="original" dangerouslySetInnerHTML={{ __html: htmlPrompt }} />;

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
        <Typography variant="h2" mr={2}>
          <strong>Prompt</strong>
        </Typography>
        <PrimaryBtn onClick={() => setEditMode(!isEditMode)}>
          {isEditMode ? 'Desactivar' : 'Activar'} Modo edición
        </PrimaryBtn>
      </Box>
      {isEditMode && (
        <>
          {/* Start show instructions section */}
          {/* <Typography variant="body1"> */}
          <Box display="flex">
            <Switch checked={showInstructions} onChange={() => setShowInstructions(!showInstructions)} />
            <Typography variant="h6">Mostrar instrucciones</Typography>
          </Box>
          {showInstructions && <PromptHelperText />}
          {/* </Typography> */}
          {/* End show instructions section */}

          {/* Start prompt playground section */}
          <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
            <Switch checked={showPromptPlayground} onChange={() => setShowPromptPlayground(!showPromptPlayground)} />
            <Typography variant="h6">Mostrar playground</Typography>
          </Box>
          {showPromptPlayground && (
            <PromptPlayground
              masterProjectId={project.masterProjectId || project.id}
              projectId={project.id}
              close={() => setShowPromptPlayground(false)}
              prompt={editedPrompt}
              commonPrompt={editedCommonPrompt}
              subprojects={masterProject?.subProjects || project.subProjects || []}
            />
          )}
          {/* End prompt playground section */}

          {/* Start common prompt section */}
          <Box display={project.masterProjectId === null ? 'block' : 'none'}>
            <Box mt={2} mb={1} display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" mr={2}>
                Prompt Común
              </Typography>
              <PrimaryBtn onClick={() => setIsEditCommonPrompt(!isEditCommonPrompt)}>
                {isEditCommonPrompt ? 'Cancelar' : ''} Editar Prompt Común
              </PrimaryBtn>
            </Box>
            <Grid mb={2} display="flex" alignItems="center">
              <textarea
                value={(isEditCommonPrompt ? editedCommonPrompt : project.commonPrompt) || ''}
                cols={120}
                rows={Math.round((project.commonPrompt?.split('\n').length || 5) * 1.25)}
                onChange={(event) => setEditedCommonPrompt(event.target.value)}
                disabled={!isEditCommonPrompt}
                style={{ marginRight: '20px' }}
              />
              {isEditCommonPrompt && (
                <Box sx={{ maxWidth: '50%', mt: 0, pt: 0 }} display={isEditCommonPrompt ? 'block' : 'none'}>
                  <DiffsComponent originalText={project.commonPrompt || ''} newText={editedCommonPrompt} />
                </Box>
              )}
            </Grid>
            <Box display={isEditCommonPrompt ? 'flex' : 'none'} flexDirection="row">
              <PrimaryBtn
                onClick={() => {
                  handleEditProject(project.id, { commonPrompt: editedCommonPrompt });
                  setIsEditCommonPrompt(false);
                }}
              >
                Guardar Prompt común
              </PrimaryBtn>
            </Box>
          </Box>
          {/* End common prompt section */}

          {/* Start project prompt section */}
          <Box>
            <Box mt={4} mb={1} display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" mr={2}>
                Prompt del proyecto
              </Typography>
              <PrimaryBtn onClick={() => setIsEditPrompt(!isEditPrompt)}>
                {isEditPrompt ? 'Cancelar' : ''} Editar Prompt
              </PrimaryBtn>
            </Box>
            <Grid mb={2} display="flex" alignItems="center">
              <textarea
                value={(isEditPrompt ? editedPrompt : project.prompt) || ''}
                cols={120}
                rows={Math.round((project.prompt?.split('\n').length || 5) * 1.25)}
                onChange={(event) => setEditedPrompt(event.target.value)}
                disabled={!isEditPrompt}
                style={{ marginRight: '20px' }}
              />
              {isEditPrompt && (
                <Box sx={{ maxWidth: '50%', mt: 0, pt: 0 }} display={isEditPrompt ? 'block' : 'none'}>
                  <DiffsComponent originalText={project.prompt || ''} newText={editedPrompt} />
                </Box>
              )}
            </Grid>
            <Box display={isEditPrompt ? 'flex' : 'none'} flexDirection="row">
              <PrimaryBtn
                onClick={() => {
                  handleEditProject(project.id, { prompt: editedPrompt });
                  setIsEditPrompt(false);
                }}
              >
                Guardar Prompt
              </PrimaryBtn>
            </Box>
          </Box>
          {/* End project prompt section */}
        </>
      )}
      <Box border="1px solid #ccc" p={2} mt={2} mb={2}>
        <Typography variant="h2" mr={2}>
          <strong>Prompt bonito</strong>
        </Typography>
        {prettyPrompt}
      </Box>
    </Box>
  );
};

export default PromptEditComponent;
