import * as React from 'react';

import { Box, Grid, Tooltip, CircularProgress, Typography } from '@mui/material';

import { useGetProjectMetricsQuery } from 'services/api/projects.api';
import { Project } from 'services/types/project';

import SimpleMetrics from './Metrics';
import { MultipleLineChart } from './MultipleLineChart';
import { MetricsBarChartStr } from './barChart';
import { HoursMetricsLineChart } from './lineChart';
import { MetricsPieChart } from './pieChart';

interface SimpleDashProps {
  project: Project;
  fromDate: string;
  toDate: string;
}

const SimpleDash: React.FC<SimpleDashProps> = ({ project, fromDate, toDate }) => {
  let specificProjectId;
  let specificSubProjectId;

  if (project.masterProjectId) {
    specificSubProjectId = project.id;
    specificProjectId = project.masterProjectId;
  } else {
    specificProjectId = project.id;
  }

  const metricsResponse = useGetProjectMetricsQuery({
    fromDate,
    toDate,
    specificProjectId,
    specificSubProjectId,
  });

  const clientsByChannelSeries = metricsResponse.data?.graphData
    ? Object.values(metricsResponse.data?.graphData.clientsByInputChannelPerDay)
    : [];
  const clientsByChannelLabels = metricsResponse.data?.graphData
    ? Object.keys(metricsResponse.data?.graphData.clientsByInputChannelPerDay)
    : [];

  return (
    <>
      <Box alignContent="center" textAlign="left">
        {metricsResponse.isFetching && (
          <CircularProgress size={50} sx={{ color: 'text.primary', ml: window.innerWidth / 100 }} />
        )}
      </Box>
      <br />
      {!!fromDate && !!toDate && fromDate < toDate ? (
        (!!metricsResponse.data?.graphData.clientsPerDay &&
          Object.keys(metricsResponse.data?.graphData.clientsPerDay).length > 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <MetricsBarChartStr
                      filteredData={metricsResponse.data?.graphData.clientsPerDay || {}}
                      type="Nuevos Leads por Día"
                    />
                  </Grid>
                  {Object.keys(metricsResponse.data?.graphData.messagesPerDayInOfficeHours).length > 1 && (
                    <Grid item>
                      <MetricsBarChartStr
                        filteredData={metricsResponse.data?.graphData.messagesPerDayInOfficeHours || {}}
                        secondSeries={metricsResponse.data?.graphData.messagesPerDayOutOfOfficeHours || {}}
                        type="Mensajes por día"
                        labels={['Horario Laboral', 'Fuera de Horario Laboral']}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <HoursMetricsLineChart
                      filteredData={metricsResponse.data?.graphData.clientsPerHour || {}}
                      type="Hora de llegada de Nuevos Leads"
                    />
                  </Grid>
                  <Grid item>
                    <HoursMetricsLineChart
                      filteredData={metricsResponse.data?.graphData.clientMessagesPerHour || {}}
                      type="Hora de Mensajes"
                    />
                  </Grid>
                  <Grid item>
                    <HoursMetricsLineChart
                      filteredData={metricsResponse.data?.graphData.distributionMessagesClients || {}}
                      type="Cantidad de Mensajes por Lead"
                    />
                  </Grid>
                  {Object.keys(metricsResponse.data?.graphData.clientsPerSubProject).length > 1 && (
                    <>
                      <Grid item>
                        <MetricsPieChart
                          filteredData={metricsResponse.data?.graphData.clientsPerSubProject || {}}
                          type="Clientes Activos por Proyecto"
                        />
                      </Grid>
                      <Grid item>
                        <MetricsBarChartStr
                          filteredData={metricsResponse.data?.graphData.clientsPerSubProject || {}}
                          type="Clientes Activos por Proyecto"
                        />
                      </Grid>
                    </>
                  )}
                  {Object.keys(metricsResponse.data?.graphData.clientsByEndReason).length > 0 && (
                    <Grid item>
                      <MetricsBarChartStr
                        filteredData={metricsResponse.data?.graphData.clientsByEndReason || {}}
                        type="Razones de término de conversaciones"
                      />
                    </Grid>
                  )}
                  {Object.keys(metricsResponse.data?.graphData.clientsByTopics).length > 0 && (
                    <Grid item>
                      <MetricsBarChartStr
                        filteredData={metricsResponse.data?.graphData.clientsByTopics || {}}
                        type="Temas hablados en conversaciones"
                      />
                    </Grid>
                  )}
                  {Object.keys(metricsResponse.data?.graphData.clientsBySource).length > 1 && (
                    <Grid item>
                      <MetricsPieChart
                        filteredData={metricsResponse.data?.graphData.clientsBySource || {}}
                        type="Leads por origen"
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <MultipleLineChart
                      type="Clientes por canal de ingreso"
                      dataSeries={clientsByChannelSeries}
                      labels={clientsByChannelLabels}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container direction={{ xs: 'row', md: 'column' }} spacing={2}>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Cantidad de leads (únicos) recibidos por Lidz"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics type="Leads Creados" data={metricsResponse.data?.metrics.activeClients || 0} />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Leads que venían con un número de teléfono ya registrado en la base de datos"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Leads Duplicados"
                          data={metricsResponse.data?.metrics.duplicateClients || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Cantidad de leads que pudieron ser contactados"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Clientes Activos"
                          data={metricsResponse.data?.metrics.chargeClients || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Tasa de leads que enviaron al menos un mensaje por sobre los Clientes Activos"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Tasa de Respuesta"
                          data={metricsResponse?.data?.metrics.responseRate || 0}
                          percentage
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title={
                        <Typography color="white">
                          Leads que no se pudieron contactar
                          <br />
                          Posibles razones:
                          <br />
                          - Información falsa de contacto
                          <br />
                          - Teléfono no actualizado en plataforma de contacto
                          <br />
                          - Al contactar al cliente, WhatsApp arroja error
                          <br />
                        </Typography>
                      }
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Tasa de Leads No Contactables"
                          data={
                            (metricsResponse.data?.metrics.notChargedClients || 0) /
                            (metricsResponse.data?.metrics.activeClients || 1)
                          }
                          percentage
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title={
                        <Typography color="white">
                          Porcentaje de mensajes fuera de horario laboral. Horario laboral considerado:
                          <li>Lunes a viernes: 9:00 a 18:00</li>
                        </Typography>
                      }
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Mensajes fuera de horario laboral"
                          data={
                            (metricsResponse.data?.metrics.totalMessagesOutOfOfficeHours || 0) /
                            (metricsResponse.data?.metrics.totalMessages || 1)
                          }
                          percentage
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Leads enriquecidos con información pública adicional a la otorgada por el cliente"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Leads con Información Externa"
                          data={metricsResponse.data?.metrics.externalInfoClients || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Leads que nuestro algoritmo considera que tienen una mayor probabilidad de cerrar una venta"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Leads con Puntaje Superior a 50"
                          data={metricsResponse.data?.metrics.over50Clients || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Promedio del largo de la conversación con cada Lead en el período seleccionado"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Promedio de Mensajes por Lead"
                          data={metricsResponse.data?.metrics.averageMessages || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Cantidad de mensajes enviados y recibidos por Lidz"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Total de Mensajes"
                          data={metricsResponse.data?.metrics.totalMessages || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Los mensajes de seguimiento son aquellos que se le mandan al menos un día después para hacer seguimiento del proceso o reactivar el interés del cliente"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Cantidad de Mensajes de Seguimiento"
                          data={metricsResponse.data?.metrics.followUpMessages || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Cantidad de cotizaciones enviadas por Lidz en el período seleccionado"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics
                          type="Cotizaciones enviadas"
                          data={metricsResponse.data?.metrics.quoteMessages || 0}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Tooltip
                      arrow
                      enterDelay={700}
                      title="Cantidad de fotos enviadas por Lidz en el período seleccionado"
                      followCursor
                      placement="top-start"
                    >
                      <span>
                        <SimpleMetrics type="Fotos enviadas" data={metricsResponse.data?.metrics.imageMessages || 0} />
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )) ||
        metricsResponse.isFetching || (
          <Typography align="center">No hay datos para mostrar en el rango seleccionado de fechas</Typography>
        )
      ) : (
        <Typography color="error" align="center">
          Seleccione un rango de fechas válido
        </Typography>
      )}
    </>
  );
};

export default SimpleDash;
