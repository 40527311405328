import React, { FC, useState, useEffect } from 'react';

// import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { Box, TextField, IconButton, Grid } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import type { EditProjectBody, Project, PropertyType } from 'services/types/project';

interface PropertyTypesEditorProps {
  project: Project | null;
  // onSave recibe un id de un project y un body de tipo que tiene un array de objetos
  handleEditProject: (projectId: number, data: EditProjectBody) => void;
}

const PropertyTypesEditor: FC<PropertyTypesEditorProps> = ({ project, handleEditProject }) => {
  const [propertyTypesDraft, setPropertyTypesDraft] = useState<PropertyType[]>(project?.propertyTypes || []);

  useEffect(() => {
    setPropertyTypesDraft(project?.propertyTypes || []);
  }, [project]);

  const handleAddPropertyType = () => {
    setPropertyTypesDraft([...propertyTypesDraft, { name: '', ufPrice: 0, description: '' }]);
  };

  const handleDeletePropertyType = (index: number) => {
    const updatedPropertyTypes = [...(propertyTypesDraft || [])];
    updatedPropertyTypes.splice(index, 1);
    setPropertyTypesDraft(updatedPropertyTypes);
  };

  const handlePropertyTypeChange = (index: number, key: keyof PropertyType, value: string | number) => {
    const updatedPropertyTypes = [...propertyTypesDraft];
    updatedPropertyTypes[index] = { ...updatedPropertyTypes[index], [key]: value };
    setPropertyTypesDraft(updatedPropertyTypes);
  };

  const handleSavePropertyTypes = () => {
    if (project) {
      // Delete propertyTypes with empty fields
      let propertyTypesDraftFiltered = propertyTypesDraft.filter(
        (propertyType) => propertyType.name && propertyType.ufPrice && propertyType.description
      ) as PropertyType[] | null;
      if (propertyTypesDraftFiltered?.length === 0) {
        propertyTypesDraftFiltered = null;
      }
      handleEditProject(project.id, { propertyTypes: propertyTypesDraftFiltered });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      {propertyTypesDraft.map((propertyType, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container spacing={2} alignItems="center" key={`propertyType-${index}`}>
          <Grid item xs={3}>
            <TextField
              label="Name"
              value={propertyType.name}
              onChange={(e) => handlePropertyTypeChange(index, 'name', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="UF Price"
              type="number"
              value={propertyType.ufPrice}
              onChange={(e) => handlePropertyTypeChange(index, 'ufPrice', parseFloat(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Description"
              value={propertyType.description}
              onChange={(e) => handlePropertyTypeChange(index, 'description', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => handleDeletePropertyType(index)} color="secondary">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
        <PrimaryBtn
          startIcon={<Add />}
          onClick={handleAddPropertyType}
          variant="contained"
          color="primary"
          sx={{ maxWidth: 200 }}
        >
          Agregar
        </PrimaryBtn>

        <PrimaryBtn
          onClick={handleSavePropertyTypes}
          variant="contained"
          color="primary"
          sx={{ marginLeft: 2, maxWidth: 200 }}
        >
          Guardar Tipos
        </PrimaryBtn>
      </div>
    </Box>
  );
};

export default PropertyTypesEditor;
