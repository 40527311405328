import React, { FC, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useGetProjectsQuery } from 'services/api/projects.api';
import type { Project } from 'services/types/project';

interface ProjectSelectorProps {
  onSelect: (project: Project | null) => void;
  projectId: number | null | undefined;
  refetchFlag: boolean;
}

const ProjectSelector: FC<ProjectSelectorProps> = ({ onSelect, projectId, refetchFlag }: ProjectSelectorProps) => {
  const { data: projects, refetch } = useGetProjectsQuery(null);
  if (projectId === null && projects?.length) {
    onSelect(projects[0]);
    return null;
  }
  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [refetchFlag]);

  useEffect(() => {
    if (projects && projectId) {
      onSelect(projects.find((x) => x.id === projectId) ?? null);
    }
  }, [projects]);
  return (
    <FormControl sx={{ minWidth: 250, paddingRight: '20px', mt: 1 }}>
      <InputLabel id="project-select-label">Proyecto</InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        value={projectId?.toString() ?? ''}
        label="Proyecto"
        onChange={(event) => onSelect(projects?.find((x) => x.id === Number(event.target.value)) ?? null)}
      >
        {projects?.map((projectElement) => (
          <MenuItem key={projectElement.id} value={projectElement.id}>
            <Typography variant="h3" color={projectElement.masterProjectId ? 'grey' : 'black'}>
              {projectElement.masterProjectId ? `- ${projectElement.name}` : projectElement.name}
              <span style={{ fontSize: '0.5em', color: '#aaa', marginLeft: '5px' }}>({projectElement.id})</span>
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProjectSelector;
